import SignUp from './components/SignUp';
import Footer from './components/navigation/Footer';
import Header from './components/navigation/Header';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import square from 'bootstrap-icons/icons/square-fill.svg';
import './App.css';
import { Parallax } from 'react-parallax';
import Section from './components/Section';

export default function App() {
  return (
    <>
      <Header />
      <div className="landing-container">
        {/* Title section */}
        <div className="title-container">
          <h1>All your shows.</h1>
          <h1>All in one place.</h1>
          <a href="#sign-up" className="btn btn-primary-lg title-button">
            Sign up for free!
          </a>
        </div>

        {/* Section 0 */}
        <Section>
          <h1>Make concert planning a stress-free experience</h1>
        </Section>

        <Section>
          <Parallax
            bgImage={square}
            bgImageStyle={{
              width: '15em',
              top: '2em',
            }}
            strength={50}
          >
            <i className="bi bi-calendar-week calendar-color" style={{ fontSize: '12em', margin: '0.5em' }} />
          </Parallax>
        </Section>

        <Section>
          <p className="section-paragraph">Set notifications so you never miss another show</p>
        </Section>

        {/* Section 1 */}
        <Section>
          <h1>Each ticket, tailored to you</h1>
        </Section>

        <Section>
          <Parallax
            bgImage={square}
            bgImageStyle={{
              width: '15em',
              top: '2em',
            }}
            strength={50}
          >
            <i className="bi bi-body-text body-text-color" style={{ fontSize: '12em', margin: '0.5em' }} />
          </Parallax>
        </Section>

        <Section>
          <p className="section-paragraph">Add notes to tickets to relive your experience!</p>
        </Section>
        {/* Sign Up */}
        <SignUp />
      </div>
      <Footer />
    </>
  );
}
