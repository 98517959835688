import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function Section({ children }: Props) {
  return (
    <div className="flex flex-col justify-center items-center w-screen h-auto mx-4">
      <div className="flex flex-col justify-center items-center w-[50vw]">{children}</div>
    </div>
  );
}
