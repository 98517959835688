interface Props {
  message: string;
  isSuccess?: boolean;
}

export default function Popup({ message, isSuccess = false }: Props) {
  return (
    <div role="alert" style={{ color: isSuccess ? 'green' : 'red', fontSize: '1.2rem' }}>
      {message}
    </div>
  );
}
