import { useState } from 'react';
import Popup from './Popup';
import '../css/signup.css';

export default function SignUp() {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [email, setEmail] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);

  const isValidEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email);
  };

  const clearForm = () => {
    setEmail('');
    setConsentCheckbox(false);
  };

  const showSuccessMessage = () => {
    setIsSuccessPopup(true);
    setPopupMessage('Success! Keep an eye out for an email from us!');
    clearForm();
  };

  const sendEmail = async () => {
    try {
      // Prepare the data to be sent in the POST request
      const date = new Date();
      const formData = {
        email: email,
        date: date,
      };

      // Make the POST request using fetch
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert formData to JSON
      });

      const result = await res.json();
      console.log(result); // Store the server response in state
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };

  const handleEmailInputChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleConsentCheckboxChange = () => {
    setConsentCheckbox((prevState) => !prevState);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Check if error occurs
    if (!isValidEmail(email)) {
      setIsSuccessPopup(false);
      setPopupMessage('Please ensure that you have provided a valid email address.');
      return;
    }
    if (!consentCheckbox) {
      setIsSuccessPopup(false);
      setPopupMessage('Please fill out the form and check that you have accepted to receive emails.');
      return;
    }
    sendEmail();
    showSuccessMessage();
  };

  return (
    <form id="sign-up" className="sign-up-container">
      {popupMessage && <Popup message={popupMessage} isSuccess={isSuccessPopup} />}

      <h1>All Aboard!</h1>
      <p>Sign up to stay up-to-date on our alpha release!</p>

      <div className="form-floating mb-3">
        <input
          type="email"
          id="email"
          className="form-control"
          placeholder="Email"
          aria-label="Email"
          value={email}
          required
          onChange={handleEmailInputChange}
        />
        <label htmlFor="email">Email</label>
      </div>

      <div style={{ display: 'flex', gap: '1em' }}>
        <input
          type="checkbox"
          name="consent-checkbox"
          id="consent-checkbox"
          required
          checked={consentCheckbox}
          onChange={handleConsentCheckboxChange}
        />
        <label htmlFor="consent-checkbox">I agree to receive emails for important news.</label>
      </div>
      <input type="submit" value="Sign Up!" className="btn btn-primary-lg title-button" onClick={handleSubmit} />
    </form>
  );
}
